<template>
    <div class="modal-wrapper" @click="closeModal">
        <div class="modal" @click.stop>
            <i @click="closeModal" class="close-modal">×</i>
            <slot name="heading"></slot>
            <slot ref="data" name="content" class="content"></slot>
            <slot name="pagination"></slot>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        closeModal: function() {
            const modalWrapper = this.$el

            modalWrapper.classList.remove('fade-in')
            
            // eslint-disable-next-line
            EventBus.$emit('closeModal')
        },
        addRep: function(e, data) {

            e.preventDefault();

            let content = e.target.textContent,
                url = `${this.$store.state.mainURL}/admin/v1/agents`,
                headers = new Headers(),
                mode = 'cors',
                method = 'POST'

            e.target.dataset.role == "submit" ? e.target.textContent = '' : ''
            e.target.dataset.role == "submit" ? e.target.classList.add('loading') : ''

            headers.append('Accept', 'application/json')
            headers.append('content-type', 'application/json')
            headers.append('Authorization', `Bearer ${this.$store.getters.getAccessToken}`)

            fetch(url, {
                mode,
                headers,
                method,
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    e.target.classList.add('success')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('success')
                        e.target.textContent = content
                    }, 2000)

                    setTimeout( function () {
                        const modalWrapper = this.$el

                        modalWrapper.classList.add('fade-out')
                        modalWrapper.classList.remove('fade-in')
                    }.bind(this), 500)

                    // eslint-disable-next-line
                    EventBus.$emit('agentAddedSuccessfully');

                    this.$store.dispatch('doGetAgentsPerPage', this.$store.getters.getAllResponse.current_page)
                    
                    // eslint-disable-next-line
                    EventBus.$emit('searchError', data.message)
                } else {
                    setTimeout(() => {
                        e.target.classList.add('failed')
                        e.target.classList.remove('loading')

                        setTimeout(() => {
                            e.target.classList.remove('failed')
                            e.target.textContent = content
                        }, 2000)

                    }, 500)

                    // eslint-disable-next-line
                    EventBus.$emit('searchError', data.message)
                }
            })


        },
        replyToComment: function (e, data) {
            e.preventDefault();

            let content = e.target.textContent,
                url = `${this.$store.state.mainURL}/admin/v1/comments/reply`,
                headers = new Headers(),
                mode = 'cors',
                method = 'POST'

            e.target.dataset.role == "submit" ? e.target.textContent = '' : ''
            e.target.dataset.role == "submit" ? e.target.classList.add('loading') : ''

            headers.append('Accept', 'application/json')
            headers.append('content-type', 'application/json')
            headers.append('Authorization', `Bearer ${this.$store.getters.getAccessToken}`)

            fetch(url, {
                mode,
                headers,
                method,
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    e.target.classList.add('success')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('success')
                        e.target.textContent = content
                    }, 2000)

                    setTimeout( function () {
                        const modalWrapper = this.$el

                        modalWrapper.classList.add('fade-out')
                        modalWrapper.classList.remove('fade-in')
                    }.bind(this), 500)
                    
                    // eslint-disable-next-line
                    EventBus.$emit('repliedSuccessfully');

                    this.$store.dispatch('doGetComments', this.$store.getters.getAllResponse.current_page)
                } else {
                    setTimeout(() => {
                    e.target.classList.add('failed')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('failed')
                        e.target.textContent = content
                    }, 2000)

                    this.$parent.errorMessages = []
                    !this.$parent.errorMessages.includes(data.message) ? this.$parent.errorMessages.push(data.message) : ''

                    // eslint-disable-next-line
                    EventBus.$emit('showErrorNotification')

                }, 500)
                }
            })
        },
        replyToClientComment: function (e, data, parentID) {
            e.preventDefault();

            let content = e.target.textContent,
                url = `${this.$store.state.mainURL}/center/v1/comments/reply`,
                headers = new Headers(),
                mode = 'cors',
                method = 'POST',
                id = parentID == null ? data.comment_id : parentID

            e.target.dataset.role == "submit" ? e.target.textContent = '' : ''
            e.target.dataset.role == "submit" ? e.target.classList.add('loading') : ''

            headers.append('Accept', 'application/json')
            headers.append('content-type', 'application/json')
            headers.append('Authorization', `Bearer ${this.$store.getters.getAccessToken}`)

            fetch(url, {
                mode,
                headers,
                method,
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    e.target.classList.add('success')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('success')
                        e.target.textContent = content
                    }, 2000)

                    setTimeout( function () {
                        const modalWrapper = this.$el

                        modalWrapper.classList.add('fade-out')
                        modalWrapper.classList.remove('fade-in')
                    }.bind(this), 500)
                    
                    // eslint-disable-next-line
                    EventBus.$emit('repliedSuccessfully');

                    this.$store.dispatch('doGetClientComment', id)
                } else {
                    setTimeout(() => {
                    e.target.classList.add('failed')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('failed')
                        e.target.textContent = content
                    }, 2000)

                    this.$parent.errorMessages = []
                    !this.$parent.errorMessages.includes(data.message) ? this.$parent.errorMessages.push(data.message) : ''

                    // eslint-disable-next-line
                    EventBus.$emit('showErrorNotification')

                }, 500)
                }
            })
        },
        addSubOrg: function(e, data) {
            let content = e.target.textContent,
                url = `${this.$store.state.mainURL}/center/v1/sub_centers`,
                headers = new Headers(),
                mode = 'cors',
                method = 'POST'

            e.target.dataset.role == "submit" ? e.target.textContent = '' : ''
            e.target.dataset.role == "submit" ? e.target.classList.add('loading') : ''

            headers.append('Accept', 'application/json')
            headers.append('content-type', 'application/json')
            headers.append('Authorization', `Bearer ${this.$store.getters.getAccessToken}`)

            fetch(url, {
                mode,
                headers,
                method,
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    e.target.classList.add('success')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('success')
                        e.target.textContent = content
                    }, 2000)

                    setTimeout( function () {
                        const modalWrapper = this.$el

                        modalWrapper.classList.add('fade-out')
                        modalWrapper.classList.remove('fade-in')
                    }.bind(this), 500)
                    
                    // eslint-disable-next-line
                    EventBus.$emit('subOrgAdded');

                    this.$store.dispatch('doGetSubOrg', this.$store.getters.getAllResponse.current_page)
                } else {
                    setTimeout(() => {
                    e.target.classList.add('failed')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('failed')
                        e.target.textContent = content
                    }, 2000)

                    this.$parent.errorMessages = []
                    !this.$parent.errorMessages.includes(data.message) ? this.$parent.errorMessages.push(data.message) : ''

                    // eslint-disable-next-line
                    EventBus.$emit('showErrorNotification')

                }, 500)
                }
            })
        },
        // add work appointment
            addWorkAppointment: function(e, data) {
            let content = e.target.textContent,
                url = `${this.$store.state.mainURL}/center/v1/workshift`,
                // url = `https://jsonplaceholder.typicode.com/posts`,
                headers = new Headers(),
                mode = 'cors',
                method = 'POST'
            e.target.dataset.role == "submit" ? e.target.textContent = '' : ''
            e.target.dataset.role == "submit" ? e.target.classList.add('loading') : ''
            headers.append('Accept', 'application/json')
            headers.append('content-type', 'application/json')
            headers.append('Authorization', `Bearer ${this.$store.getters.getAccessToken}`)
            fetch(url, {
                mode,
                headers,
                method,
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    e.target.classList.add('success')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('success')
                        e.target.textContent = content
                    }, 2000)

                    setTimeout( function () {
                        const modalWrapper = this.$el

                        modalWrapper.classList.add('fade-out')
                        modalWrapper.classList.remove('fade-in')
                    }.bind(this), 500)
                    
                    // eslint-disable-next-line
                    EventBus.$emit('workAppointmentAdded');
                    this.$store.dispatch('doGetWorkAppointments', this.$store.getters.getAllResponse.current_page)
                } else {
                    setTimeout(() => {
                    e.target.classList.add('failed')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('failed')
                        e.target.textContent = content
                    }, 2000)

                    this.$parent.errorMessages = []
                    !this.$parent.errorMessages.includes(data.message) ? this.$parent.errorMessages.push(data.message) : ''

                    // eslint-disable-next-line
                    EventBus.$emit('showErrorNotification')

                }, 500)
                }
            })
        },
        addSubOrgUser: function(e, data) {
            let content = e.target.textContent,
                url = `${this.$store.state.mainURL}/center/v1/organizations/user/create`,
                headers = new Headers(),
                mode = 'cors',
                method = 'POST'

            e.target.dataset.role == "submit" ? e.target.textContent = '' : ''
            e.target.dataset.role == "submit" ? e.target.classList.add('loading') : ''

            headers.append('Accept', 'application/json')
            headers.append('content-type', 'application/json')
            headers.append('Authorization', `Bearer ${this.$store.getters.getAccessToken}`)

            fetch(url, {
                mode,
                headers,
                method,
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    e.target.classList.add('success')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('success')
                        e.target.textContent = content
                    }, 2000)

                    setTimeout( function () {
                        const modalWrapper = this.$el

                        modalWrapper.classList.add('fade-out')
                        modalWrapper.classList.remove('fade-in')
                    }.bind(this), 500)

                    // eslint-disable-next-line
                    EventBus.$emit('subOrganizationUserAdded')

                    this.$store.dispatch('doGetCurrentSubOrganizationUsers', this.$store.getters.getAllResponse.current_page)
                } else {
                    setTimeout(() => {
                    e.target.classList.add('failed')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('failed')
                        e.target.textContent = content
                    }, 2000)

                    this.$parent.errorMessages = []
                    !this.$parent.errorMessages.includes(data.message) ? this.$parent.errorMessages.push(data.message) : ''

                    // eslint-disable-next-line
                    EventBus.$emit('showErrorNotification')

                }, 500)
                }
            })
        },
        addOffer: function(e, data) {
            let content = e.target.textContent,
                url = `${this.$store.state.mainURL}/center/v1/offers`,
                headers = new Headers(),
                mode = 'cors',
                method = 'POST'

            e.target.dataset.role == "submit" ? e.target.textContent = '' : ''
            e.target.dataset.role == "submit" ? e.target.classList.add('loading') : ''

            headers.append('Accept', 'application/json')
            headers.append('content-type', 'application/json')
            headers.append('Authorization', `Bearer ${this.$store.getters.getAccessToken}`)

            fetch(url, {
                mode,
                headers,
                method,
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    e.target.classList.add('success')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('success')
                        e.target.textContent = content
                    }, 2000)

                    setTimeout( function () {
                        const modalWrapper = this.$el

                        modalWrapper.classList.add('fade-out')
                        modalWrapper.classList.remove('fade-in')

                    }.bind(this), 500)

                    // eslint-disable-next-line
                    EventBus.$emit('offerAdded')

                } else {
                    setTimeout(() => {
                    e.target.classList.add('failed')
                    e.target.classList.remove('loading')

                    setTimeout(() => {
                        e.target.classList.remove('failed')
                        e.target.textContent = content
                    }, 2000)

                    this.$parent.errorMessages = []

                    Object.keys(data.errors).forEach(key => {
                        !this.$parent.errorMessages.includes(data.errors[key][0]) ? this.$parent.errorMessages.push(data.errors[key][0]) : ''
                    })

                    // eslint-disable-next-line
                    EventBus.$emit('showErrorNotification')

                }, 500)
                }
            })
        }
    },
    mounted() {

        const modalWrapper = this.$el

        // eslint-disable-next-line
        EventBus.$on('openModal', () => {
            modalWrapper.classList.contains('table') ? '' : modalWrapper.classList.add('fade-in')
        })

        // eslint-disable-next-line
        EventBus.$on('openUsersModal', (id) => {

            this.$store.dispatch('doGetSubOrganizationUsers', id)
                .then(() => {

                    modalWrapper.classList.contains('table') ? modalWrapper.classList.add('fade-in') : ''
                    
                })

        })

        // eslint-disable-next-line
        EventBus.$on('closeModal', () => {
            modalWrapper.classList.add('fade-out')
        })
    }
}
</script>

<style lang="sass" scoped>
.modal-wrapper
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 0
    background-color: rgba(0, 0, 0, .5)
    z-index: 11
    overflow: hidden
    will-change: height, opacity
    transition: all .15s linear

    h3.info
        margin: -10px 0 10px
        padding: 10px
        background-color: rgba(241, 196, 15, 0.2)
        color: #f39c12
        border-radius: 6px

    &.fade-out
        opacity: 0
        height: 0
        
        .modal
            animation: scale-out .4s cubic-bezier(0.23, 1, 0.32, 1) forwardss
            animation-delay: 200ms

            @keyframes scale-out
                from
                    transform: translate(-50%, -50%) scale(1)
                to
                    transform: translate(-50%, -50%) scale(0)

    &.fade-in
        opacity: 1
        height: 100vh

        .modal
            animation: scale-in .4s cubic-bezier(0.23, 1, 0.32, 1) forwards
            animation-delay: 200ms

            @keyframes scale-in
                from
                    transform: translate(-50%, -50%) scale(0)
                to
                    transform: translate(-50%, -50%) scale(1)
    &.policies
        .modal
            max-width: 750px

    input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0

    .modal
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%) scale(0)
        z-index: 11
        width: 90vw
        max-width: 550px
        max-height: 80vh
        overflow-y: scroll
        overflow-x: hidden
        background-color: #fff
        padding: 15px
        border-radius: 15px

        .contant
            max-height: 60vh
            overflow-y: scroll

        &::-webkit-scrollbar
            width: 2px

        /* Track */
        &::-webkit-scrollbar-track
            background: transparent
            border-radius: 5px

        /* Handle */
        &::-webkit-scrollbar-thumb
            background: #E7B500
            border-radius: 5px

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover
            background: #E7B500

        .modal-content
            max-height: calc(80vh - 130px)
            overflow-y: scroll
            overflow-x: hidden

            &::-webkit-scrollbar
                width: 2px

            /* Track */
            &::-webkit-scrollbar-track
                background: transparent
                border-radius: 5px

            /* Handle */
            &::-webkit-scrollbar-thumb
                background: #E7B500
                border-radius: 5px

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover
                background: #E7B500

        .custom-container
            position: relative

            button
                position: absolute
                top: calc(50% - 5px)
                left: 15px
                width: 25px
                height: 25px
                transform: translateY(-50%)
                background: url('../assets/images/innovation.svg') #fff
                border: 0
                cursor: pointer
                outline: 0

        .container
            width: 100%
            display: flex
            align-items: center
            justify-content: space-between

        .input-container
            width: calc(100% - 5px)
            display: flex
            flex-direction: column
            align-items: flex-start
            justify-content: flex-start

            &.half
                width: calc(50% - 5px)

            span
                padding-left: 10px

            input 
                appearance: none
                -webkit-appearance: none

                &::-webkit-inner-spin-button
                    display: none
                &::-webkit-clear-button
                    display: none
                &::-webkit-calendar-picker-indicator
                    color: #E7B400

        .file-uploader
            position: relative
            width: 200px
            height: 200px
            border-radius: 50%
            margin: 0 auto 20px

            label
                display: block
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                cursor: pointer
                width: calc(100% - 20px)
                height: calc(100% - 20px)
                overflow: hidden
                display: flex
                align-items: center
                justify-content: center

                img
                    max-width: 100%
                    max-height: 100%

                &::before
                    content: '\e813'
                    font-family: "fontello"
                    font-size: 2.5em
                    color: #fff
                    position: absolute
                    bottom: -120%
                    left: 50%
                    transform: translateX(-50% )
                    padding: 7px 0
                    display: block
                    text-align: center
                    width: 100%
                    height: 50px
                    line-height: 50px
                    background-color: rgba(0, 0, 0, .5)
                    transition: bottom .3s ease

                &:hover::before
                    bottom: 0

            input
                display: none

        i.close-modal
            position: absolute
            top: 5px
            left: 20px
            font-size: 2.2em
            cursor: pointer

        h1
            font-weight: 100
            padding-bottom: 5px
            margin-bottom: 20px
            border-bottom: 1px solid #E7B500

            @media screen and (max-width: 475px)
                font-size: 1em
                font-weight: 600
        
        input:not([type="submit"]), select, textarea
            width: 100%
            padding: 7px 15px
            border-radius: 25px
            border: 1px solid #eee
            box-shadow: 5px 5px 10px #eee
            font-family: inherit
            margin: 0 0 10px 5px
            outline: 0
            resize: none

            &.half
                width: calc(50% - 5px)

        select
            -moz-appearance: none
            -webkit-appearance: none
            position: relative
            color: #000

            &::ms-expand
                display: none

        textarea:not(.comment-reply)
            height: 100px

        .comment-reply
            min-height: 80px
            max-height: 200px
            border-radius: 5px
            resize: vertical

        textarea
            &::-webkit-scrollbar
                width: 0px

            /* Track */
            &::-webkit-scrollbar-track
                background: transparent
                border-radius: 5px

            /* Handle */
            &::-webkit-scrollbar-thumb
                background: #E7B500
                border-radius: 5px

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover
                background: #E7B500

        .comment
            padding: 7px
            font-size: .9em
            color: #777
            background-color: #f9f9f9
            border-radius: 10px
            margin-bottom: 10px
            line-height: 20px

            &__owner
                display: block
                font-weight: bold
                color: #DBAB00
                line-height: 20px

            &__content
                display: block
                max-height: 150px
                padding: 10px 0
                overflow-y: scroll
                overflow-x: hidden

                &::-webkit-scrollbar
                    width: 1px

                /* Track */
                &::-webkit-scrollbar-track
                    background: transparent
                    border-radius: 5px

                /* Handle */
                &::-webkit-scrollbar-thumb
                    background: #E7B500
                    border-radius: 5px

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover
                    background: #E7B500


        .actions
            display: flex
            justify-content: space-between
            align-items: center
            height: 60px

            button
                background-color: #000
                color: #fff
                border-radius: 25px
                border: 0
                font-family: inherit
                cursor: pointer
                font-size: 1.1em
                padding: 4px 13px
                margin-top: 15px
                width: 140px
                height: 40px
                outline: none
                transition: all .3s ease
                position: relative

                &[data-role="close"]
                    background-color: #f9f9f9
                    color: #000
                    box-shadow: 3px 3px 10px #eee

                    &:hover
                        box-shadow: 3px 3px 10px #ccc

                &::before
                    content: ''
                    position: absolute
                    top: 50% 
                    left: 50%
                    transform: translate(-50%, -50%)
                    width: 40px
                    height: 40px
                    background-color: #999
                    border-radius: 50%
                    transition: all .3s ease
                    z-index: -1
                    visibility: hidden

                &::after
                    content: url('../assets/images/thumb.svg')
                    position: absolute
                    top: 50% 
                    left: 50%
                    transform: translate(-50%, -50%) scale(.5)
                    padding: 10px 7px 10px 10px
                    width: 40px
                    height: 40px
                    border-radius: 50%
                    background-color: #000
                    transition: back .3s ease
                    visibility: hidden

                &.loading
                    width: 40px
                    height: 40px
                    border-radius: 50%
                    pointer-events: none

                    &::before
                        visibility: visible
                        animation: scale-up 1s linear infinite

                        @keyframes scale-up
                            0%
                                transform: translate(-50%, -50%) scale(1)
                                opacity: 1
                            100%
                                transform: translate(-50%, -50%) scale(2)
                                opacity: 0

                &.success
                    width: 60px
                    height: 60px
                    border-radius: 50%
                    padding: 20px
                    background-color: #2ecc71
                    pointer-events: none

                    &::after
                        visibility: visible
                        background-color: #2ecc71
                        transform: translate(-50%, -50%) scale(1)

                &.failed
                    width: 60px
                    height: 60px
                    border-radius: 50%
                    background-color: #e74c3c
                    pointer-events: none

                    &::after
                        visibility: visible
                        background-color: #e74c3c
                        transform: translate(-50%, -50%) scale(1) rotate(-180deg)

        table
            width: 100%
            text-align: center

            thead
                background-color: #E7B400
                color: #fff

                th
                    padding: 5px 0
                    font-weight: 100

            tr:nth-of-type(even)
                background-color: #ECECEC

            tr:nth-of-type(odd)
                background-color: #fff

            td
                padding: 5px

            i
                font-size: 1.2em
                background-color: #fff
                box-shadow: 3px 3px 10px #ddd
                margin: 0 5px
                padding: 0 5px
                border-radius: 4px
                cursor: pointer

        h3
            font-weight: 100

        .renew
            margin: 10px

            form
                padding: 10px

            &__info
                background-color: #f9f9f9
                padding: 10px
                border-radius: 5px

            &__row
                display: flex
                justify-content: space-between

            &__row:last-of-type
                margin-top: 7px
                padding-top: 3px
                border-top: 1px dashed #ddd

                .sum
                    &::after
                        content: 'ريال'
                        padding-right: 5px
                        font-size: .7em
                        font-weight: bold

            &__payment

                &__via
                    display: flex
                    justify-content: space-between
                    margin-top: 10px
                    padding: 10px
                    overflow-x: scroll

                    &::-webkit-scrollbar
                        height: 2px

                    /* Track */
                    &::-webkit-scrollbar-track
                        background: transparent
                        border-radius: 5px

                    /* Handle */
                    &::-webkit-scrollbar-thumb
                        background: #E7B500
                        border-radius: 5px

                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover
                        background: #E7B500

                    &__card
                        max-width: calc(25% - 10px)
                        min-width: 80px
                        display: flex
                        align-items: center
                        border-radius: 5px
                        padding: 5px
                        margin-left: 10px
                        box-shadow: 3px 3px 10px #eee
                        border: 1px solid transparent
                        cursor: pointer
                        transition: border-color .3s ease

                        &.active
                            border: 1px solid #E7B500

                        img
                            width: 100%
                
                &__data
                    margin-top: 15px
                    &__row
                        display: flex
                        align-items: center

                        span
                            margin: 0 0 0 5px
                            font-size: 1.4em
                            font-weight: 100

                        input[type="tel"]
                            width: 100px

                        input.expire
                            width: 60px

        .policy
            list-style: none

            li
                padding: 0 30px 20px 20px
                position: relative
                color: #080600
                line-height: 30pt

                &::before
                    content: attr(data-index)
                    position: absolute
                    top: -5px
                    right: 0
                    font-weight: bold
                    font-size: 1.3em
                    color: #e7b400
</style>

